import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': 'linear-gradient(90deg, #583DFF 0%, #9F30FF 25.52%, #6B5DF0 50.52%, #3489FF 75.52%, #0DB0D4 100%)',
		'primary-dark': 'linear-gradient(90deg, #583DFF 0%, #9F30FF 25.52%, #6B5DF0 50.52%, #3489FF 75.52%, #0DB0D4 100%)',
		'accent': '159, 48, 255',
		'accent-plus': '255, 255, 255',
	},
});
